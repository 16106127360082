@import '~bloko/common/styles/colors';

.parent-filter {
    display: flex;
    align-items: center;
    gap: 12px;

    &:hover {
        cursor: pointer;
    }

    &:active {
        background-color: @color-gray-10;
        transition: background-color 200ms linear;
    }
}

.parent-filter-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
}
