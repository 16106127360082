// tempexp_35925-file
@import '@hh.ru/magritte-ui/breakpoints';

.card-wrapper {
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.text {
    display: flex;
    flex-direction: column;
    padding: 12px 12px 24px;
}

.phones {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;

    @media @screen-bloko-gt-xs {
        flex-direction: row;
        gap: 0;
    }
}
