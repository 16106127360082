// tempexp_37037_file

.fixed {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 24px;
    right: 0;
    left: 0;
    z-index: 1000;
}

.hidden {
    display: none;
}

.content {
    position: relative;
}

.text {
    padding: 12px;
    width: calc(100% - 120px);
    min-height: 44px;
}

.image {
    width: 120px;
    height: 120px;
    background-image: url('man.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: -28px;
    right: -12px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.action-wrapper {
    padding: 4px;
}
