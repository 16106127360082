@import '@hh.ru/magritte-ui/breakpoints';

.button-group {
    display: flex;
}

.dashboard-vacancy {
    @media @screen-magritte-gt-s {
        min-height: 300px;
        background-image: url('girlonchair.svg');
        background-repeat: no-repeat;
        background-position: top 60px right 0;

        :global(.theme-zarplata) & {
            background-image: url('girlonchair-zarplata.svg');
        }
    }
}

// tempexp_37037_start
.dashboard-vacancy-without-bg {
    background: none;
    display: flex;
    justify-content: space-between;
}

// tempexp_37037_end

.dashboard-vacancy-list-item {
    margin-bottom: 12px;

    &::before {
        content: '\2014\00a0';
    }
}

.gift-link {
    width: fit-content;
}
