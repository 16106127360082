@import '../../../../bloko/common/styles/colors';

.horizontal-divider-mixin() {
    height: 1px;
    border: 0;
    background: @color-gray-20;
}

.horizontal-divider {
    .horizontal-divider-mixin();
}
