@import '~bloko/common/styles/_abstract-modal';
@import '~bloko/common/styles/_abstract-control';

.success-info {
    text-align: center;
    margin-right: -(@scrollbar-max-with - @control-box-shadow-focus-visible-width);
}

.success-icon {
    width: 40px;
    height: 40px;
    background: url('images/done.svg');
    margin: auto;
}
