@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.adv-mark-container {
    position: relative;
}

.adv-mark {
    position: absolute;
    height: 100%;
    width: 8px;
    border-radius: 0 @magritte-static-border-radius-200 @magritte-static-border-radius-200 0;
    background-color: @magritte-color-text-warning-secondary;
    left: -25px;
}
