// tempexp_37037_file
.banner-body {
    width: 344px;
    height: 240px;
    background-image: url('man.svg');
    background-repeat: no-repeat;
    background-position: bottom 25px right 40px;
}

.banner-text {
    padding: 12px 12px 80px;
}
