@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/rounded-corner-base';

.chat-bot {
    background: @color-orange-10;
}

.chat-bot_rounded {
    border-radius: @rounded-corner-default;
}

.chat-bot-wrapper {
    padding: 16px;

    @media @screen-gt-xs {
        padding: 20px;
    }
}
